import { FC } from "react";
import s from "./LinkPlace.module.scss";
type LinkPlaceT = {
  id: any;
};
export const LinkPlace: FC<LinkPlaceT> = ({ ...props }) => {
  const { id } = props;
  const config2D = `${process.env.REACT_APP_CONFIG_PLAYER_URL}/${id}`;
  const config3D = `${process.env.REACT_APP_CONFIG_STUDIO_URL}/${id}`;

  function openUrl(link: any) {
    window.open(link, "_blank");
  }
  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <div className={s.label} onClick={() => openUrl(config2D)}>
          Player
        </div>
      </div>
      <div className={s.item}>
        <div className={s.label} onClick={() => openUrl(config3D)}>
          Studio
        </div>
      </div>
    </div>
  );
};
